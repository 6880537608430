/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import LogoFractal from '@/images/img/logotipo-fractal-black.svg'
import Item1 from '@/images/demandas/item-1.svg'
import Item2 from '@/images/demandas/item-2.svg'
import Item3 from '@/images/demandas/item-3.svg'
import Item4 from '@/images/demandas/item-4.svg'
import IcoRedesSociais from '@/images/demandas/redes-sociais.svg'
import IcoSites from '@/images/demandas/sites.svg'
import IcoCampanhas from '@/images/demandas/campanhas.svg'
import IcoIdVisual from '@/images/demandas/identidade-visual.svg'
import IcoOutros from '@/images/demandas/outros.svg'

import { 
  demandasContent,
  boxContent,
  boxService,
}  from '../assets/styles/Other.styles'

const DemandasComunicacao = () => {
  return (
    <Fragment>
      <section css={demandasContent}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="text-center">
                <img
                  src={LogoFractal}
                  alt='Fractal - Reinventing Businesses'
                  title='Fractal - Reinventing Businesses'
                  style={{ width: 260, marginBottom: 32 }}
                />
              </div>
            </Col>
          </Row>
          <div css={boxContent}>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <h3>Solicitação de serviços</h3>
                <p>Mais organização, agilidade e eficiência na entrega.</p>
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={2} sm={12} />
              <Col lg={4} md={4} sm={12}>
                <img
                  src={Item1}
                />
              </Col>
              <Col lg={4} md={4} sm={12}>
                <img
                  src={Item2}
                  style={{ marginTop: 64 }}
                />
              </Col>
              <Col lg={2} md={2} sm={12} />
            </Row>
            <Row>
              <Col lg={2} md={2} sm={12} />
              <Col lg={4} md={4} sm={12}>
                <img
                  src={Item3}
                />
              </Col>
              <Col lg={4} md={4} sm={12}>
                <img
                  src={Item4}
                  style={{ marginTop: 64 }}
                />
              </Col>
              <Col lg={2} md={2} sm={12} />
            </Row>
          </div>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div css={boxService}>
                <img src={IcoRedesSociais} />
                <h3>Redes sociais</h3>
                <a href="/demandascomunicacao/redes-sociais">Acessar</a>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div css={boxService}>
                <img src={IcoCampanhas} />
                <h3>Campanhas</h3>
                <a href="/demandascomunicacao/campanhas">Acessar</a>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div css={boxService}>
                <img src={IcoSites} />
                <h3>Sites</h3>
                <a href="/demandascomunicacao/sites">Acessar</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={2} md={2} sm={12} />
            <Col lg={4} md={4} sm={12}>
              <div css={boxService}>
                <img src={IcoIdVisual} />
                <h3>Identidade Visual</h3>
                <a href="/demandascomunicacao/identidade-visual">Acessar</a>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div css={boxService}>
                <img src={IcoOutros} />
                <h3>Outros*</h3>
                <a href="/demandascomunicacao/outros">Acessar</a>
              </div>
            </Col>
            <Col lg={2} md={2} sm={12} />
          </Row>
          <Row>
            <div className="text-center"  style={{ width: '100%', marginTop: 32 }}>
              <p style={{ textTransform: 'none', fontSize: 20 }}>*Outros: Apresentações, e-book, convites, folder, panfleto, banner, Landing Page e E-mail marketing</p>
            </div>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default DemandasComunicacao;
